<lis-dialog>
  <ng-container id="header">{{ data.headline }}</ng-container>

  <p [innerHTML]="data.content"></p>

  <ng-container id="footer">
    <div class="flex items-center justify-end">
      <button lis-button type="button" (click)="onCloseClick()">Fertig</button>
    </div>
  </ng-container>
</lis-dialog>
