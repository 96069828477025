import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LisDialogInfoInput } from '@lis-types';

@Component({
  templateUrl: './dialog-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogInfoComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LisDialogInfoInput,
    private dialogRef: MatDialogRef<LisDialogInfoInput>
  ) {}

  ngOnInit(): void {}

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
